exports = module.exports = require("../../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, ".styles_container__2OFSO {\n  width: 300px;\n  max-height: 400px;\n  padding: 20px 0;\n  margin: 15px;\n  border-bottom: 3px #9a2d2d solid;\n  display: flex;\n  flex-direction: column;\n  justify-content: center; }\n  @media (min-width: 768px) {\n    .styles_container__2OFSO {\n      width: 300px;\n      margin: 30px; } }\n\n.styles_textWrapper__26k2G {\n  color: #2f3542;\n  font-weight: 900;\n  display: flex;\n  flex-direction: column;\n  justify-content: space-around;\n  margin-top: 15px;\n  margin-left: 15px; }\n\n.styles_image__WD3Ui {\n  position: relative;\n  background-position: center;\n  background-size: 140px;\n  background-repeat: no-repeat;\n  height: 120px;\n  width: 90px;\n  margin-left: 15px; }\n  @media (min-width: 768px) {\n    .styles_image__WD3Ui {\n      background-size: 160px;\n      height: 170px;\n      width: 130px; } }\n\n.styles_iconWrapper__3rzNF {\n  margin: 3px 0; }\n\n.styles_iconText__2Ag1A {\n  color: #2f3542;\n  font-size: 12px;\n  margin: 0 0 0 6px; }\n  @media (min-width: 768px) {\n    .styles_iconText__2Ag1A {\n      font-size: 14px; } }\n  @media (min-width: 768px) {\n    .styles_iconText__2Ag1A {\n      margin: 0 0 0 12px; } }\n\n.styles_contactName__XHF_9 {\n  color: #2f3542;\n  font-size: 16px;\n  margin: 4px 0; }\n\n.styles_contactTitle__3UyCk {\n  color: #2f3542;\n  margin: 8px 0;\n  font-size: 20px;\n  font-weight: 900;\n  margin-left: 15px; }\n", ""]);

// Exports
exports.locals = {
	"container": "styles_container__2OFSO",
	"textWrapper": "styles_textWrapper__26k2G",
	"image": "styles_image__WD3Ui",
	"iconWrapper": "styles_iconWrapper__3rzNF",
	"iconText": "styles_iconText__2Ag1A",
	"contactName": "styles_contactName__XHF_9",
	"contactTitle": "styles_contactTitle__3UyCk"
};