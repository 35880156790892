import React from "react";
import styles from "./styles.module.scss";
import image1 from "../../images/logo.svg";
import image2 from "../../images/sakervatten.png";

const Certificates = () => {
  return (
    <div className={styles.container}>
      <img src={image1} className={styles.logo1} alt="heta arbeten logo" />
      <img src={image2} className={styles.logo2} alt="säkervatten logo" />
    </div>
  );
};
export default Certificates;
