exports = module.exports = require("../../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, ".styles_container__xcF42 {\n  display: flex;\n  flex-direction: column;\n  position: absolute;\n  background-color: rgba(118, 122, 122, 0.753);\n  width: 80%;\n  height: unset;\n  margin-top: auto;\n  margin-bottom: auto; }\n  @media (min-width: 768px) {\n    .styles_container__xcF42 {\n      width: 600px;\n      height: 310px;\n      top: 120px; } }\n  .styles_container__xcF42 h1 {\n    margin: 0;\n    padding: 30px;\n    padding-bottom: 0;\n    font-size: 22px; }\n    @media (min-width: 768px) {\n      .styles_container__xcF42 h1 {\n        font-size: 30px; } }\n  .styles_container__xcF42 p {\n    padding: 30px;\n    padding-top: 10px;\n    margin: 0; }\n\n.styles_button__1YzRQ {\n  background-color: #2f3542;\n  color: #f1f2f6;\n  padding: 15px 10px;\n  margin: 0 30px;\n  margin-bottom: 30px;\n  width: unset;\n  font-size: 13px;\n  display: flex;\n  justify-content: center;\n  cursor: pointer; }\n  @media (min-width: 768px) {\n    .styles_button__1YzRQ {\n      width: 250px;\n      font-size: 16px;\n      padding: 15px; } }\n\n@media (min-width: 768px) {\n  .styles_left__urbMH {\n    left: 50px; } }\n\n@media (min-width: 768px) {\n  .styles_right__1rpvE {\n    right: 50px; } }\n\n.styles_none__sbwpC {\n  display: none; }\n", ""]);

// Exports
exports.locals = {
	"container": "styles_container__xcF42",
	"button": "styles_button__1YzRQ",
	"left": "styles_left__urbMH",
	"right": "styles_right__1rpvE",
	"none": "styles_none__sbwpC"
};