import React from "react";
import "./App.scss";
import LandingPage from "./pages/LandingPage";
import "./icons";

function App() {
  return <LandingPage />;
}

export default App;
