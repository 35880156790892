import React from "react";
import styles from "./styles.module.scss";
import TextArea from "./TextArea";

const About = ({ textPosition, background, text, header, buttonText }) => {
  return (
    <div
      className={styles.container}
      style={{ backgroundImage: `url(${background})` }}
    >
      <TextArea
        textPosition={textPosition}
        background={background}
        text={text}
        header={header}
        buttonText={buttonText}
      />
    </div>
  );
};
export default About;
