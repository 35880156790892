exports = module.exports = require("../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, ".styles_container__3x5BM {\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  margin-bottom: 50px;\n  background-color: #f1f2f6;\n  color: #2f3542; }\n  @media (min-width: 768px) {\n    .styles_container__3x5BM {\n      margin-bottom: 0;\n      justify-content: center;\n      align-items: flex-start;\n      flex-direction: row;\n      flex-wrap: wrap; } }\n\n.styles_footerContent__3VNHs {\n  margin: 20px 12px; }\n  @media (min-width: 768px) {\n    .styles_footerContent__3VNHs {\n      margin: 40px 40px; } }\n  .styles_footerContent__3VNHs p {\n    font-size: 16px;\n    text-align: center; }\n    @media (min-width: 768px) {\n      .styles_footerContent__3VNHs p {\n        text-align: left; } }\n  .styles_footerContent__3VNHs p:first-child {\n    font-size: 17px;\n    font-weight: 900;\n    color: #9a2d2d; }\n\n.styles_iconWrapper__3FAH7 {\n  margin: 1em 0;\n  display: flex;\n  justify-content: center; }\n  @media (min-width: 768px) {\n    .styles_iconWrapper__3FAH7 {\n      justify-content: flex-start; } }\n\n.styles_iconText__3UfKb {\n  margin: 0 0 0 12px; }\n\n.styles_socialMediaWrapper__3TBrT {\n  display: flex;\n  justify-content: center; }\n  @media (min-width: 768px) {\n    .styles_socialMediaWrapper__3TBrT {\n      justify-content: flex-start; } }\n  .styles_socialMediaWrapper__3TBrT a:nth-child(2) {\n    margin-left: 12px; }\n", ""]);

// Exports
exports.locals = {
	"container": "styles_container__3x5BM",
	"footerContent": "styles_footerContent__3VNHs",
	"iconWrapper": "styles_iconWrapper__3FAH7",
	"iconText": "styles_iconText__3UfKb",
	"socialMediaWrapper": "styles_socialMediaWrapper__3TBrT"
};