import React from "react";
import styles from "./styles.module.scss";
import ContactCard from "./ContactCard";
import sune from "../../images/sune.jpg";
import johan from "../../images/johan.jpg";

const Contact = () => {
  return (
    <div className={styles.container}>
      <h1>Kontakt</h1>
      <div className={styles.contactWrapper}>
        <ContactCard
          image={sune}
          name={`Christoffer "Sune" Berglund (VD)`}
          phone="073-2544137"
          email="sune@dalarorservice.com"
          title="Kontakt företag"
        />
        <ContactCard
          image={johan}
          name="Johan Emretsson (Vice VD)"
          phone="073-9460806"
          email="johan@dalarorservice.com"
          title="Kontakt privatpersoner"
        />
      </div>
    </div>
  );
};
export default Contact;
