import React from "react";
import Header from "../../components/Header";
import Contact from "../../components/Contact";
import Footer from "../../components/Footer";
import About from "../../components/About";
import Certificates from "../../components/Certificates";
import background1 from "../../images/bussarna.jpg";
import background2 from "../../images/tools.jpg";
import { aboutSectionHeader1, aboutSectionText1 } from "./texts";
import ImageGallerys from "../../components/NewImageGallery";

const LandingPage = () => {
  return (
    <div>
      <Header />
      <About
        textPosition="left"
        background={background1}
        text={aboutSectionText1}
        header={aboutSectionHeader1}
        buttonText="Gå till Dala Rörentreprenad"
      />
      <Contact />
      <ImageGallerys />
      <About textPosition="none" background={background2} />
      <Certificates />
      <Footer />
    </div>
  );
};
export default LandingPage;
