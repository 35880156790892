import React from "react";
import styles from "./styles.module.scss";
import cx from "classnames";

const TextArea = ({ textPosition, text, header, buttonText }) => {
  return (
    <div className={cx(styles.container, styles[textPosition])}>
      <h1>{header}</h1>
      <p>{text}</p>
      {buttonText && (
        <a href="https://www.dalarorentreprenad.com" className={styles.button}>
          {buttonText}
        </a>
      )}
    </div>
  );
};
export default TextArea;
