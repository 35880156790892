import React, { Component, Fragment } from "react";
import styles from "./styles.module.scss";
import Carousel, { Modal, ModalGateway } from "react-images";
import image1 from "../../images/image1.jpg";
import image2 from "../../images/image2.jpg";
import image3 from "../../images/image3.jpg";
import image4 from "../../images/image4.jpg";
import image5 from "../../images/image5.jpg";
import image6 from "../../images/image6.jpg";
import image7 from "../../images/image7.jpg";
import image8 from "../../images/image8.jpg";
import image9 from "../../images/image9.jpg";
import image11 from "../../images/image11.jpg";
import image12 from "../../images/image12.jpg";
import image13 from "../../images/image13.jpg";
import image15 from "../../images/image15.jpg";
import image16 from "../../images/image16.jpg";
import image17 from "../../images/image17.jpg";
import image18 from "../../images/image18.jpg";
import image19 from "../../images/image19.jpg";
import image20 from "../../images/image20.jpg";
import image21 from "../../images/image21.jpg";
import image22 from "../../images/image22.jpg";
import image23 from "../../images/image23.jpg";
import image24 from "../../images/image24.jpg";
import image25 from "../../images/image25.jpg";
import image26 from "../../images/image26.jpg";

const images = [
  { src: image1 },
  { src: image2 },
  { src: image3 },
  { src: image4 },
  { src: image5 },
  { src: image6 },
  { src: image7 },
  { src: image8 },
  { src: image9 },
  { src: image11 },
  { src: image12 },
  { src: image13 },
  { src: image15 },
  { src: image16 },
  { src: image17 },
  { src: image18 },
  { src: image19 },
  { src: image20 },
  { src: image21 },
  { src: image22 },
  { src: image23 },
  { src: image24 },
  { src: image25 },
  { src: image26 },
];

export default class ImageGallery extends Component {
  state = {
    selectedIndex: 0,
    lightboxIsOpen: false,
  };
  toggleLightbox = (selectedIndex) => {
    this.setState((state) => ({
      lightboxIsOpen: !state.lightboxIsOpen,
      selectedIndex,
    }));
  };
  render() {
    const { selectedIndex, lightboxIsOpen } = this.state;

    return (
      <div className={styles.container}>
        <h1>Tidigare arbeten</h1>
        <p>(Klicka för större bild)</p>
        <Fragment>
          <Gallery>
            {images.map(({ src }, j) => (
              <Image onClick={() => this.toggleLightbox(j)} key={j}>
                <img
                  alt="alt"
                  src={src}
                  style={{
                    cursor: "pointer",
                    position: "absolute",
                    maxWidth: "100%",
                  }}
                />
              </Image>
            ))}
          </Gallery>

          <ModalGateway>
            {lightboxIsOpen ? (
              <Modal
                onClose={this.toggleLightbox}
                styles={{
                  blanket: (base) => ({
                    ...base,
                    backgroundColor: "rgba(255,255,255,0.85)",
                  }),
                  dialog: (base) => ({
                    ...base,
                    maxWidth: 800,
                  }),
                }}
              >
                <Carousel currentIndex={selectedIndex} views={images} />
              </Modal>
            ) : null}
          </ModalGateway>
        </Fragment>
      </div>
    );
  }
}

const Gallery = (props) => (
  <div
    className={styles.gallery}
    style={{
      overflow: "hidden",
    }}
    {...props}
  />
);

const Image = (props) => (
  <div
    className={styles.image}
    style={{
      backgroundColor: "#eee",
      boxSizing: "border-box",
      float: "left",
      overflow: "hidden",
      position: "relative",

      ":hover": {
        opacity: 0.9,
      },
    }}
    {...props}
  />
);
