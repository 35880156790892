exports = module.exports = require("../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, ".styles_container__w4OUu {\n  background-color: #57606f;\n  background: linear-gradient(rgba(87, 96, 111, 0.6), rgba(47, 53, 66, 0.5));\n  padding: 35px 12px; }\n  @media (min-width: 768px) {\n    .styles_container__w4OUu {\n      padding: 50px; } }\n  .styles_container__w4OUu h1 {\n    color: #f1f2f6;\n    margin-bottom: 0;\n    text-align: center; }\n  .styles_container__w4OUu p {\n    margin: 0;\n    margin-bottom: 24px;\n    text-align: center;\n    color: #f1f2f6; }\n\n.styles_carouselWrapper__3kMaE {\n  width: 50%; }\n\n.styles_image__KhWyJ {\n  width: calc(50% - 10px);\n  padding-bottom: 50%;\n  margin: 5px;\n  box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.1); }\n  @media (min-width: 768px) {\n    .styles_image__KhWyJ {\n      margin: 5px;\n      padding-bottom: 16%;\n      width: calc(25% - 10px); } }\n\n.styles_gallery__13tb5 {\n  margin-left: -4px;\n  margin-right: -4px; }\n  @media (min-width: 768px) {\n    .styles_gallery__13tb5 {\n      margin-left: -2px;\n      margin-right: -2px; } }\n", ""]);

// Exports
exports.locals = {
	"container": "styles_container__w4OUu",
	"carouselWrapper": "styles_carouselWrapper__3kMaE",
	"image": "styles_image__KhWyJ",
	"gallery": "styles_gallery__13tb5"
};